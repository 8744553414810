import { styled } from '@mui/material/styles';
import { TimeMachineSnapshotInfo } from './TimeMachineSnapshotInfo';
import { TimeMachineLastMoveView } from './TimeMachineLastMoveView';

export const HEIGHT_PX = 128;

/**
 * An aggregated display of the time machine state and move history.
 */
export function TimeMachinePreview() {
  return (
    <Container>
      <StyledTimeMachineSnapshotInfo />
      <StyledTimeMachineLastMoveView />
    </Container>
  );
}

const MARGIN_SIZE = 2;

const Container = styled('div')`
  margin: ${({ theme }) => theme.spacing(MARGIN_SIZE)};
  height: ${HEIGHT_PX}px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const StyledTimeMachineSnapshotInfo = styled(TimeMachineSnapshotInfo)`
  flex-basis: 50%;
`;

const StyledTimeMachineLastMoveView = styled(TimeMachineLastMoveView)`
  flex-basis: 50%;
`;
