import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import useTheme from '@mui/styles/useTheme';
import {
  chooseBlockComponentForPlayer,
  BlockComponent,
  choosePlayerPreviewColorFromTheme,
} from '../../../components/blocks';
import {
  PLAYER_ONE_BLOCK,
  PLAYER_TWO_BLOCK,
} from '../../../messages/gameState';
import type { ClientInfo } from '../roomSlice';
import { Typography } from '@mui/material';
import { Star as StarIcon } from '@mui/icons-material';
import type { RootState } from '../../../app/rootReducer';

type PlayersViewProps = {
  players: [ClientInfo | null, ClientInfo | null];
  className?: string;
};
export function PlayersView({ players, className }: PlayersViewProps) {
  const theme = useTheme();
  const selfId = useSelector((state: RootState) => state.room.clients.self?.id);

  const [playerOne, playerTwo] = players;
  const playerOneColor = choosePlayerPreviewColorFromTheme(
    theme,
    PLAYER_ONE_BLOCK
  );
  const playerTwoColor = choosePlayerPreviewColorFromTheme(
    theme,
    PLAYER_TWO_BLOCK
  );
  const PlayerOneBlock = chooseBlockComponentForPlayer(PLAYER_ONE_BLOCK);
  const PlayerTwoBlock = chooseBlockComponentForPlayer(PLAYER_TWO_BLOCK);

  const playerOneNode =
    playerOne != null ? (
      <PlayerLineInfo
        {...playerOne}
        BlockComponent={PlayerOneBlock}
        strokeColor={playerOneColor}
        isMe={selfId != null && playerOne.id === selfId}
      />
    ) : (
      <PlayerEmptyLineInfo
        {...playerOne}
        BlockComponent={PlayerOneBlock}
        strokeColor={playerOneColor}
      />
    );

  const playerTwoNode =
    playerTwo != null ? (
      <PlayerLineInfo
        {...playerTwo}
        BlockComponent={PlayerTwoBlock}
        strokeColor={playerTwoColor}
        isMe={selfId != null && playerTwo.id === selfId}
      />
    ) : (
      <PlayerEmptyLineInfo
        {...playerTwo}
        BlockComponent={PlayerTwoBlock}
        strokeColor={playerTwoColor}
      />
    );

  return (
    <PlayerLineContainer className={className}>
      {playerOneNode}
      {playerTwoNode}
    </PlayerLineContainer>
  );
}

const BLOCK_SIZE = '0.9em';

type PlayerLineInfoProps = ClientInfo & {
  BlockComponent: BlockComponent;
  strokeColor: string;
  isMe: boolean;
};
function PlayerLineInfo({
  displayName,
  BlockComponent,
  strokeColor,
  isMe,
}: PlayerLineInfoProps) {
  return (
    <PlayerLineText>
      <BlockComponent
        stroke={strokeColor}
        width={BLOCK_SIZE}
        height={BLOCK_SIZE}
      />
      <Typography variant="body2">{displayName}</Typography>
      {isMe && <StyledStarIcon />}
    </PlayerLineText>
  );
}

type PlayerEmptyLineInfoProps = {
  BlockComponent: BlockComponent;
  strokeColor: string;
};
function PlayerEmptyLineInfo({
  BlockComponent,
  strokeColor,
}: PlayerEmptyLineInfoProps) {
  return (
    <PlayerLineText>
      <BlockComponent
        stroke={strokeColor}
        width={BLOCK_SIZE}
        height={BLOCK_SIZE}
      />
      <Typography variant="body2" color="textSecondary">
        <i>None</i>
      </Typography>
    </PlayerLineText>
  );
}

const PlayerLineContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
`;

const PlayerLineText = styled('span')`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    margin-left: ${({ theme }) => theme.spacing(1)} !important;
  }
`;

const StyledStarIcon = styled(StarIcon)`
  font-size: 1rem;
  color: yellow;
`;
