import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import invariant from 'invariant';
import { Typography, Tooltip, Chip } from '@mui/material';
import { Restore as RestoreIcon } from '@mui/icons-material';
import {
  selectTimeMachine,
  selectTimeMachineSelectedSnapshot,
  selectTimeMachineSnapshots,
} from '../roomSelectors';
import { chooseTimeMachineSnapshotSelectedIndex } from '../roomSlice';
import { AppDispatch } from '../../../app/store';

type TimeMachineSnapshotInfoProps = {
  className?: string;
};

/**
 * Shows the details of the currently selected time machine snapshot.
 * If no snapshot is selected, then this just shows the move count.
 */
export function TimeMachineSnapshotInfo({
  className,
}: TimeMachineSnapshotInfoProps) {
  const dispatch = useDispatch<AppDispatch>();

  const timeMachine = useSelector(selectTimeMachine);
  const selectedSnapshot = useSelector(selectTimeMachineSelectedSnapshot);
  const allSnapshots = useSelector(selectTimeMachineSnapshots) ?? [];

  if (selectedSnapshot == null) {
    return (
      <Container className={className}>
        <Typography variant="caption">
          {allSnapshots.length === 1 ? 'move' : 'moves'}
        </Typography>
        <Typography variant="h3">{allSnapshots.length}</Typography>
        <Typography variant="caption">total</Typography>
      </Container>
    );
  }

  invariant(timeMachine?.selectedIndex != null, 'Snapshot should be selected');

  const stopTimeMachine = () => {
    dispatch(chooseTimeMachineSnapshotSelectedIndex({ index: null }));
  };
  const moveNumber = timeMachine.selectedIndex + 1;

  return (
    <Container className={className}>
      <StyledChip
        size="small"
        color="secondary"
        label="Snapshot"
        deleteIcon={
          <Tooltip title="Back to game">
            <RestoreIcon />
          </Tooltip>
        }
        onDelete={stopTimeMachine}
      />
      <span>
        <Typography variant="h3" display="inline">
          {moveNumber}
          <StyledSup>{ordinalSuffix(moveNumber)}</StyledSup>
        </Typography>
        <Typography variant="body2" display="inline">
          /{allSnapshots.length}
        </Typography>
      </span>
      <Typography variant="caption">viewing</Typography>
    </Container>
  );
}

const Container = styled('div')`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
`;

const StyledChip = styled(Chip)`
  font-size: 85%;
`;

const StyledSup = styled('sup')`
  font-size: 35%;
  position: relative;
  top: -0.5em;
`;

function ordinalSuffix(num: number): string {
  if (num >= 11 && num <= 13) {
    return 'th';
  }
  switch (num % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}
