import { createElement, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router';
import { styled } from '@mui/material/styles';
import {
  Button,
  Collapse,
  Container,
  Card,
  CardContent,
  TextField,
  Typography,
  Link,
  CircularProgress,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import { GAME_ROOM_ROUTE } from '../../app/routes';
import { useThunkDispatch } from '../../common/useThunkDispatch';
import { createGameRoom } from './joinerSlice';
import { reset } from '../room/roomSlice';

export function JoinerRoute() {
  const [gameRoomName, setGameRoomName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useThunkDispatch();

  const onSubmit = async (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    // Get rid of any stale game state first
    dispatch(reset({}));
    setLoading(true);
    try {
      // Then create the new game room
      const { gameRoomName: createdGameRoomName } = unwrapResult(
        await dispatch(createGameRoom({ gameRoomName, displayName }))
      );
      history.push({
        pathname: GAME_ROOM_ROUTE.buildPath({
          gameRoomName: createdGameRoomName,
        }),
      });
    } catch {
      setLoading(false);
    }
  };

  const toggleShowMoreOptions = () => setShowMoreOptions(!showMoreOptions);

  const showMoreOptionsLink = (
    <Link
      href="#"
      color="textSecondary"
      onClick={(evt: React.SyntheticEvent) => {
        evt.preventDefault();
        toggleShowMoreOptions();
      }}
      underline="hover"
    >
      Show options
      {createElement(showMoreOptions ? ExpandLessIcon : ExpandMoreIcon, {
        fontSize: 'inherit',
      })}
    </Link>
  );

  const moreOptions = (
    <Collapse in={showMoreOptions}>
      <TextField
        label="Room name"
        value={gameRoomName}
        disabled={isLoading}
        variant="outlined"
        size="small"
        margin="normal"
        fullWidth={true}
        onChange={(evt) => setGameRoomName(evt.target.value)}
      />
      <TextField
        label="Display name"
        value={displayName}
        disabled={isLoading}
        variant="outlined"
        size="small"
        margin="normal"
        fullWidth={true}
        onChange={(evt) => setDisplayName(evt.target.value)}
      />
    </Collapse>
  );

  const submitButton = (
    <StyledSubmitButton
      type="submit"
      disabled={isLoading}
      variant="contained"
      color="primary"
      fullWidth={true}
    >
      {isLoading ? <CircularProgress color="secondary" /> : <>Create Game</>}
    </StyledSubmitButton>
  );

  return (
    <StyledContainer maxWidth="xs">
      <StyledCard>
        <CardContent>
          <StyledForm noValidate={true} onSubmit={onSubmit}>
            {submitButton}
            <Typography align="right" variant="subtitle2">
              {showMoreOptionsLink}
            </Typography>
            {moreOptions}
          </StyledForm>
        </CardContent>
      </StyledCard>
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledForm = styled('form')`
  width: 100%;
`;

const StyledSubmitButton = styled(Button)`
  margin: ${({ theme }) => theme.spacing(1, 0, 1)};
  min-height: ${({ theme }) => theme.spacing(7)};
`;
