import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { useSuperTMasonClient } from '../../../messages/superTMasonClient';
import type { ClientBeginGame } from '../../../messages/out';
import { Button, Tooltip } from '@mui/material';
import { PlayersView } from './PlayersView';
import {
  selectPlayerClients,
  selectArePlayersFilled,
  selectIsCurrentClientAPlayer,
} from '../roomSelectors';

export function BeforeStartStatusBar() {
  const canBeginGame = useSelector(selectArePlayersFilled);
  const isPlayer = useSelector(selectIsCurrentClientAPlayer);
  const masonClient = useSuperTMasonClient();
  const playerClients = useSelector(selectPlayerClients);

  const onBegin = () => {
    masonClient.query<ClientBeginGame>({
      type: 'ClientBeginGame',
    });
  };
  const actionButtonNode = (
    <Button
      variant="contained"
      color="primary"
      disabled={!(canBeginGame && isPlayer)}
      onClick={onBegin}
    >
      Begin Game
    </Button>
  );

  // Wrapping buttons in spans allow them to have tooltips when disabled

  let actionSectionNode;
  if (isPlayer && !canBeginGame) {
    actionSectionNode = (
      <Tooltip title="Not enough players">
        <span>{actionButtonNode}</span>
      </Tooltip>
    );
  } else if (canBeginGame && !isPlayer) {
    actionSectionNode = (
      <Tooltip title="You are not an active player">
        <span>{actionButtonNode}</span>
      </Tooltip>
    );
  } else {
    actionSectionNode = actionButtonNode;
  }

  return (
    <Container>
      <PlayersView players={playerClients} />
      {actionSectionNode}
    </Container>
  );
}

const Container = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
