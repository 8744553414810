import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { useSuperTMasonClient } from '../../../messages/superTMasonClient';
import type { ClientAckEndOfGame } from '../../../messages/out';
import { Button } from '@mui/material';
import { PlayersView } from './PlayersView';
import { selectPlayerClients } from '../roomSelectors';

export function TerminatedStatusBar() {
  const masonClient = useSuperTMasonClient();
  const playerClients = useSelector(selectPlayerClients);

  const onAckEndOfGame = () => {
    masonClient.query<ClientAckEndOfGame>({
      type: 'ClientAckEndOfGame',
    });
  };

  return (
    <Container>
      <PlayersView players={playerClients} />
      <Button variant="contained" color="primary" onClick={onAckEndOfGame}>
        Reset
      </Button>
    </Container>
  );
}

const Container = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
