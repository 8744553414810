export const PLAYER_ONE_BLOCK = 1 as const;
export const PLAYER_TWO_BLOCK = -1 as const;
export const EMPTY_BLOCK = 0 as const;

export type PlayerBlock = typeof PLAYER_ONE_BLOCK | typeof PLAYER_TWO_BLOCK;
export type Block = PlayerBlock | typeof EMPTY_BLOCK;

export type LocalGroup = [Block, Block, Block];
export type LocalGrid = [LocalGroup, LocalGroup, LocalGroup];
export type GlobalGroup = [LocalGrid, LocalGrid, LocalGrid];
export type GlobalGrid = [GlobalGroup, GlobalGroup, GlobalGroup];
export type Board = GlobalGrid;

export type GameState = {
  board: Board;
  limit: [number, number] | null;
  turn: PlayerBlock;
};

export type PlayerMove = {
  global: [number, number];
  local: [number, number];
};
