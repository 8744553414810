import { configureStore, Unsubscribe } from '@reduxjs/toolkit';
import rootReducer, { RootState } from './rootReducer';

const store = configureStore({
  reducer: rootReducer
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export function observeStore<T>(
  reduxStore: StoreType,
  select: (state: RootState) => T,
  onChange: (previousState: T, currentState: T) => void
): Unsubscribe {
  let currentState: T = select(reduxStore.getState());

  function handleChange() {
    let nextState = select(reduxStore.getState());
    if (nextState !== currentState) {
      onChange(currentState, nextState);
      currentState = nextState;
    }
  }

  const unsubscribe = reduxStore.subscribe(handleChange);
  return unsubscribe;
}

export type StoreType = typeof store;
export type AppDispatch = typeof store.dispatch;
export default store;
