import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type {
  RoomState,
  GameStarted,
  GameRoomStateSync,
  GameStateSync,
  GamePlayedTurn,
  GameReset,
  GameLocalVictory,
  GameCompleted,
} from '../../messages/in';
import {
  GameState,
  LocalGrid,
  PlayerMove,
  PlayerBlock,
  PLAYER_ONE_BLOCK,
  PLAYER_TWO_BLOCK,
} from '../../messages/gameState';
import type { ClientId, ClientDisplayName } from '../../messages/client';
import type {
  ClientPeerConnected,
  ClientPeerDisconnected,
  ClientWelcome,
  ClientDisplayNameChanged,
  GamePlayersSync,
} from '../../messages/in';
import { keyBy, pull, uniq } from 'lodash-es';
import invariant from 'invariant';

export type ClientInfo = {
  id: ClientId;
  displayName: ClientDisplayName;
};

interface ClientsState {
  self?: ClientInfo;
  all: {
    byId: Record<ClientId, ClientInfo>;
    currentlyConnected: ClientId[];
  };
  players: [ClientId | null, ClientId | null];
}

export type TimeMachineSnapshot = {
  game: GameState;
  victoryLayout: LocalGrid;
  move: PlayerMove;
  turn: PlayerBlock;
  player: ClientId;
};
export type TimeMachineState = {
  snapshots: Array<TimeMachineSnapshot>;
  selectedIndex: number | null;
};

export type VictoryState =
  | {
      type: 'Winner';
      clientId: ClientId;
    }
  | {
      type: 'Tie';
    };

export interface RoomSliceState {
  clients: ClientsState;
  roomState?: RoomState;
  game?: {
    state: GameState;
    victoryLayout: LocalGrid;
    timeMachine: TimeMachineState;
    victory: VictoryState | null;
  };
}

const initialState: RoomSliceState = {
  clients: {
    all: {
      byId: {},
      currentlyConnected: [],
    },
    players: [null, null],
  },
};

const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    peerConnected(state, { payload }: PayloadAction<ClientPeerConnected>) {
      const { client } = payload;
      state.clients.all.byId[client.id] = client;
      state.clients.all.currentlyConnected = uniq([
        ...state.clients.all.currentlyConnected,
        client.id,
      ]);
    },
    peerDisconnected(
      state,
      { payload }: PayloadAction<ClientPeerDisconnected>
    ) {
      pull(state.clients.all.currentlyConnected, payload.id);
    },
    displayNameChanged(
      state,
      { payload }: PayloadAction<ClientDisplayNameChanged>
    ) {
      const { clients } = state;
      const { id, displayName } = payload;
      clients.all.byId[id].displayName = displayName;
      if (clients.self?.id === id) {
        clients.self.displayName = displayName;
      }
    },
    welcome(state, { payload }: PayloadAction<ClientWelcome>) {
      const { clients } = state;
      clients.self = payload.self;
      clients.all = {
        byId: keyBy(payload.clients, (client) => client.id),
        currentlyConnected: payload.clients.map((client) => client.id),
      };
    },
    gamePlayersSync(state, { payload }: PayloadAction<GamePlayersSync>) {
      const { clients } = state;
      clients.players = payload.players;
    },
    gameStateSync(state, { payload }: PayloadAction<GameStateSync>) {
      const { data } = payload;
      if (data == null) {
        delete state.game;
        return;
      }
      const oldState = state.game;

      const flipTurn = (turn: PlayerBlock): PlayerBlock => {
        switch (turn) {
          case PLAYER_ONE_BLOCK:
            return PLAYER_TWO_BLOCK;
          case PLAYER_TWO_BLOCK:
            return PLAYER_ONE_BLOCK;
        }
      };
      const getPlayerClientForTurn = (turn: PlayerBlock): ClientId => {
        const { players } = state.clients;
        let player;
        switch (turn) {
          case PLAYER_ONE_BLOCK:
            player = players[0];
            break;
          case PLAYER_TWO_BLOCK:
            player = players[1];
            break;
        }
        invariant(
          player != null,
          'Empty player entry found while game is on-going'
        );
        return player;
      };

      const lastTurn = flipTurn(data.state.turn);
      const lastPlayer = getPlayerClientForTurn(lastTurn);

      const allSnapshots = oldState?.timeMachine.snapshots ?? [];
      if (data.lastMove != null) {
        // if this is a new move, then create a snapshot
        allSnapshots.push({
          game: data.state,
          move: data.lastMove,
          victoryLayout: data.layout,
          turn: lastTurn,
          player: lastPlayer,
        });
      } else {
        // if lastMove is not specified, we just update the existing snapshot
        const lastSnapshot = allSnapshots.pop();
        if (lastSnapshot != null) {
          allSnapshots.push({
            game: data.state,
            move: lastSnapshot.move,
            victoryLayout: data.layout,
            turn: lastTurn,
            player: lastPlayer,
          });
        }
      }

      state.game = {
        state: data.state,
        victoryLayout: data.layout,
        timeMachine: {
          snapshots: allSnapshots,
          selectedIndex: oldState?.timeMachine.selectedIndex ?? null,
        },
        victory: null,
      };
    },
    gamePlayedTurn(state, _: PayloadAction<GamePlayedTurn>) {
      const { game } = state;
      invariant(game != null, 'Played turn before game was started');
    },
    gameLocalVictory(state, _: PayloadAction<GameLocalVictory>) {
      const { game } = state;
      invariant(game != null, 'Local victory happened before game was started');
    },
    gameRoomStateSync(state, { payload }: PayloadAction<GameRoomStateSync>) {
      state.roomState = payload.roomState;
    },
    gameCompleted(state, { payload }: PayloadAction<GameCompleted>) {
      const { game } = state;
      invariant(
        game != null,
        'Game was completed before game state was synced'
      );
      if (payload.winner == null) {
        game.victory = { type: 'Tie' };
      } else {
        const { players } = state.clients;
        const playerIndex = payload.winner === PLAYER_ONE_BLOCK ? 0 : 1;
        const clientId = players[playerIndex];
        invariant(clientId != null, 'Victor should exist');
        game.victory = {
          type: 'Winner',
          clientId: clientId,
        };
      }
    },
    gameStarted(state, _: PayloadAction<GameStarted>) {
      const { game } = state;
      invariant(game != null, 'Game was started before game state was synced');
      game.timeMachine.snapshots = [];
    },
    gameReset(state, _: PayloadAction<GameReset>) {
      delete state.game;
    },
    chooseTimeMachineSnapshotSelectedIndex(
      state,
      { payload }: PayloadAction<{ index: number | null }>
    ) {
      const { index } = payload;
      const { game } = state;
      invariant(game != null, 'Tried to use time machine without game state');
      invariant(
        index == null ||
          (index >= 0 && index < game.timeMachine.snapshots.length),
        'Time machine selected snapshot index is out of range'
      );
      game.timeMachine.selectedIndex = index;
    },
    reset(state, _: PayloadAction<{}>) {
      delete state.game;
      delete state.roomState;
      state.clients = initialState.clients;
    },
  },
});

export const {
  peerConnected,
  peerDisconnected,
  displayNameChanged,
  welcome,
  gamePlayersSync,
  gameStateSync,
  gamePlayedTurn,
  gameLocalVictory,
  gameRoomStateSync,
  gameCompleted,
  gameStarted,
  gameReset,
  chooseTimeMachineSnapshotSelectedIndex,
  reset,
} = roomSlice.actions;

export default roomSlice.reducer;
