import { styled } from '@mui/material/styles';
import { Skeleton } from '@mui/material';
import { OutlinedContent } from '../../../components/paper';
import useTheme from '@mui/styles/useTheme';

const GAME_VIEW_SIZE_PERCENTAGE = 75;
const GAME_INFO_SIZE_PERCENTAGE = 100 - GAME_VIEW_SIZE_PERCENTAGE;
const MARGIN = 2;

const SKELETON_ANIMATION = 'wave';

// TODO: clean up

export const GameViewContainer = styled(OutlinedContent)`
  margin: ${({ theme }) => theme.spacing(MARGIN)};
  flex-basis: ${GAME_VIEW_SIZE_PERCENTAGE}%;
  height: calc(100% - ${({ theme }) => '2 * ' + theme.spacing(MARGIN)});
  min-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export function GameViewContainerSkeleton() {
  const theme = useTheme();
  return (
    <Skeleton
      variant="rectangular"
      animation={SKELETON_ANIMATION}
      height={`calc(100% - 2 * ${theme.spacing(MARGIN)})`}
      style={{
        minWidth: 350,
        flexBasis: `${GAME_VIEW_SIZE_PERCENTAGE}%`,
        margin: theme.spacing(MARGIN),
      }}
    />
  );
}

export const GameInfoContainer = styled(OutlinedContent)`
  margin: ${({ theme }) => theme.spacing(MARGIN, MARGIN, MARGIN, 0)};
  flex-basis: ${GAME_INFO_SIZE_PERCENTAGE}%;
  height: calc(100% - ${({ theme }) => '2 * ' + theme.spacing(MARGIN)});
  min-width: 300px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
`;

export function GameInfoContainerSkeleton() {
  const theme = useTheme();
  return (
    <Skeleton
      variant="rectangular"
      animation={SKELETON_ANIMATION}
      height={`calc(100% - 2 * ${theme.spacing(MARGIN)})`}
      style={{
        minWidth: 300,
        maxWidth: 500,
        flexBasis: `${GAME_INFO_SIZE_PERCENTAGE}%`,
        margin: theme.spacing(MARGIN, MARGIN, MARGIN, 0),
      }}
    />
  );
}
