import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
  selectIsCurrentClientAPlayer,
  selectPlayerClients,
} from '../roomSelectors';
import { useSuperTMasonClient } from '../../../messages/superTMasonClient';
import { ClientAckEndOfGame } from '../../../messages/out';
import { Button, Tooltip } from '@mui/material';
import { PlayersView } from './PlayersView';

export function EndedStatusBar() {
  const masonClient = useSuperTMasonClient();
  const playerClients = useSelector(selectPlayerClients);
  const isCurrentClientAPlayer = useSelector(selectIsCurrentClientAPlayer);

  const onReset = () => {
    masonClient.query<ClientAckEndOfGame>({
      type: 'ClientAckEndOfGame',
    });
  };

  const resetButtonNode = (
    <Button
      variant="contained"
      color="primary"
      disabled={!isCurrentClientAPlayer}
      onClick={onReset}
    >
      Reset
    </Button>
  );

  const resetButtonWithTooltip = isCurrentClientAPlayer ? (
    resetButtonNode
  ) : (
    <Tooltip title="You are not an active player">
      <span>{resetButtonNode}</span>
    </Tooltip>
  );

  return (
    <Container>
      <PlayersView players={playerClients} />
      {resetButtonWithTooltip}
    </Container>
  );
}

const Container = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
