import { GenericErrorCard } from './GenericErrorCard';

type ConnectionErrorContentProps = {
  onReconnect: () => void;
};
export function ConnectionErrorContent({
  onReconnect,
}: ConnectionErrorContentProps) {
  return (
    <GenericErrorCard
      title="Oh noes..."
      errorDetails={<>There was an error connecting to the game.</>}
      onReconnect={onReconnect}
    />
  );
}
