import { styled } from '@mui/material/styles';
import { useSuperTMasonClient } from '../../../messages/superTMasonClient';
import { Button, Typography } from '@mui/material';
import type { ClientAckEndOfGame } from '../../../messages/out';

export function TerminatedDisabledOverlay() {
  const masonClient = useSuperTMasonClient();

  const onReset = () => {
    masonClient.query<ClientAckEndOfGame>({
      type: 'ClientAckEndOfGame',
    });
  };

  return (
    <Container>
      <Typography paragraph={true} variant="body2" align="center">
        The game abruptly ended before it was finished.
        <br />
        An active player likely left the game.
      </Typography>
      <Button
        onClick={onReset}
        variant="contained"
        color="primary"
        size="small"
      >
        Reset
      </Button>
    </Container>
  );
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
