import { styled } from '@mui/material/styles';
import useResizeObserver from 'use-resize-observer';
import {
  GameStatusBar,
  HEIGHT_PX as GAME_STATUS_BAR_HEIGHT_PX,
} from './statusBar/GameStatusBar';
import {
  BottomStatusBar,
  HEIGHT_PX as BOTTOM_STATUS_BAR_HEIGHT_PX,
} from './bottomStatusBar/BottomStatusBar';
import { GameGlobalGridView } from './GameGlobalGridView';

// TODO: move to subfolder
export function GameStateView() {
  return (
    <GameViewContainer>
      <AspectRatioMaintainer>
        <StatusBarContainer>
          <GameStatusBar />
        </StatusBarContainer>
        <GridContainer>
          <GameGlobalGridView />
        </GridContainer>
        <StatusBarContainer>
          <BottomStatusBar />
        </StatusBarContainer>
      </AspectRatioMaintainer>
    </GameViewContainer>
  );
}

const GameViewContainer = styled('div')`
  width: 100%;
  flex: 1 0 auto;
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;

const StatusBarContainer = styled('div')`
  width: calc(
    100% - ${GAME_STATUS_BAR_HEIGHT_PX}px - ${BOTTOM_STATUS_BAR_HEIGHT_PX}px
  );
`;

const GridContainer = styled('div')`
  width: calc(
    100% - ${GAME_STATUS_BAR_HEIGHT_PX}px - ${BOTTOM_STATUS_BAR_HEIGHT_PX}px
  );
  height: calc(
    100% - ${GAME_STATUS_BAR_HEIGHT_PX}px - ${BOTTOM_STATUS_BAR_HEIGHT_PX}px
  );
`;

function AspectRatioMaintainer({ children }: React.PropsWithChildren<{}>) {
  const {
    ref: measureRef,
    width = 400,
    height = 400,
  } = useResizeObserver<HTMLDivElement>();
  const minSize = Math.min(width, height);
  return (
    <AspectRatioMeasuringContainer ref={measureRef}>
      <AspectRatioInnerContainer minSize={minSize}>
        {children}
      </AspectRatioInnerContainer>
    </AspectRatioMeasuringContainer>
  );
}

const AspectRatioMeasuringContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AspectRatioInnerContainer = styled('div')<{ minSize: number }>`
  width: ${({ minSize }) => minSize}px;
  height: ${({ minSize }) => minSize}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
