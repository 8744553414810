import { styled } from '@mui/material/styles';
import { TimeMachineNavigation } from '../timeMachine/TimeMachineNavigation';

export const HEIGHT_PX = 64;

/**
 * Status bar that appears underneath the game grid.
 */
export function BottomStatusBar() {
  return (
    <BottomStatusBarContainer>
      <TimeMachineNavigation />
    </BottomStatusBarContainer>
  );
}

const BottomStatusBarContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${HEIGHT_PX}px;
`;
