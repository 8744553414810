import { INT32_MIN, INT32_MAX } from '../../commons/numerics/limits';

/**
 * A unique identifier for a query.
 */
export type QueryId = number;
export function isValidQueryId(value: unknown): value is QueryId {
  return (
    typeof value === 'number' &&
    Number.isInteger(value) &&
    value >= INT32_MIN &&
    value <= INT32_MAX
  );
}

/**
 * A type that can be identified by a tag of `T`.
 */
export type TypeTagged<T> = { type: T };

/**
 * A query that can be identified with an ID.
 */
export type QueryIdentifiable<T = QueryId> = { queryId: T };

/**
 * A Mason message type.
 */
export type Mason<Tag extends string = string, Props extends {} = {}> = Props &
  TypeTagged<Tag>;
export function isAnyMason(value: unknown): value is Mason {
  return (
    typeof value === 'object' &&
    value != null &&
    'type' in value &&
    typeof (value as Record<string, unknown>)?.type === 'string'
  );
}

/**
 * A Mason message type with request ID.
 */
export type QueryMason<
  Tag extends string = string,
  Props extends {} = {}
> = Mason<Tag, Props & QueryIdentifiable>;
export function isQueryMason(value: unknown): value is QueryMason {
  return (
    isAnyMason(value) &&
    'queryId' in value &&
    isValidQueryId((value as Record<string, unknown>)?.queryId)
  );
}
