import { styled } from '@mui/material/styles';
import { Divider } from '@mui/material';
import {
  SuperTMasonClientContext,
  useCreateSuperTMasonClient,
} from '../../../messages/superTMasonClient';
import { GameStateView } from '../GameStateView';
import { useDispatchActionForIncomingMason } from '../useDispatchActionForIncomingMason';
import { ClientsView } from '../clientsView/ClientsView';
import { TimeMachinePreview } from '../timeMachine/TimeMachinePreview';
import { GameViewContainer, GameInfoContainer } from './layout';

type GameRoomContentProps = {
  encryptedToken: string;
  onConnectionError: () => void;
  onConnectionClosed: () => void;
};
export function GameRoomContent({
  encryptedToken,
  onConnectionError,
  onConnectionClosed,
}: GameRoomContentProps) {
  const masonClient = useCreateSuperTMasonClient(
    encryptedToken,
    onConnectionError,
    onConnectionClosed
  );
  useDispatchActionForIncomingMason(masonClient);

  return (
    <SuperTMasonClientContext.Provider value={masonClient}>
      <PageGameContentContainer>
        <GameViewContainer>
          <GameStateView />
        </GameViewContainer>
        <GameInfoContainer>
          <StyledClientsView />
          <Divider />
          <StyledTimeMachinePreview />
          <Divider />
          <StyledChatBox />
        </GameInfoContainer>
      </PageGameContentContainer>
    </SuperTMasonClientContext.Provider>
  );
}

const PageGameContentContainer = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const StyledClientsView = styled(ClientsView)`
  flex-basis: 30%;
  max-height: 350px;
  overflow: auto;
`;
const StyledTimeMachinePreview = styled(TimeMachinePreview)`
  flex-basis: 10%;
`;
// TODO: chat box
const StyledChatBox = styled('div')`
  flex-basis: 60%;
  overflow: auto;
`;
