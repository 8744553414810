import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import type { AppDispatch } from '../../../app/store';
import { useParams } from 'react-router';
import { joinGameRoom } from '../../joiner/joinerSlice';
import { RouteParams, GAME_ROOM_ROUTE } from '../../../app/routes';
import { GameRoomContent } from './GameRoomContent';
import { ConnectionClosedContent } from './ConnectionClosedContent';
import { selectJoinerRoom } from '../../joiner/joinerSelectors';
import { ConnectionErrorContent } from './ConnectionErrorContent';
import { GenericErrorCard } from './GenericErrorCard';
import { Typography } from '@mui/material';
import { GameRoomRouteSuspenseFallback } from './GameRoomRouteSuspenseFallback';

export default GameRoomRoute;

export function GameRoomRoute() {
  const room = useSelector(selectJoinerRoom);
  const { gameRoomName } = useParams<RouteParams<typeof GAME_ROOM_ROUTE>>();
  const dispatch = useDispatch<AppDispatch>();

  switch (room.loadingStatus) {
    case 'none':
      dispatch(joinGameRoom(gameRoomName));
      return <GameRoomRouteSuspenseFallback />;
    case 'loading':
      return <GameRoomRouteSuspenseFallback />;
    case 'error':
      return (
        <PageBackgroundContainer>
          <GenericErrorCard
            title="Oopsies..."
            errorDetails={
              <Typography align="center">
                There was an error connecting to the server. <br /> Perhaps the
                game room does not exist.
              </Typography>
            }
          />
        </PageBackgroundContainer>
      );
    case 'complete':
      const { name, encryptedToken } = room.data;
      if (name !== gameRoomName) {
        // The token in the redux store is for a different room... reload.
        dispatch(joinGameRoom(gameRoomName));
        return <GameRoomRouteSuspenseFallback />;
      }
      return <GameRoom name={name} encryptedToken={encryptedToken} />;
  }
}

type GameRoomProps = {
  name: string;
  encryptedToken: string;
};
function GameRoom({ encryptedToken }: GameRoomProps) {
  const [connectionStatus, setConnectionStatus] = useState<
    'ok' | 'closed' | 'error'
  >('ok');

  const onConnectionClosed = useCallback(() => {
    setConnectionStatus('closed');
  }, [setConnectionStatus]);

  const onConnectionError = useCallback(() => {
    setConnectionStatus('error');
  }, [setConnectionStatus]);

  const onReconnect = useCallback(() => {
    setConnectionStatus('ok');
  }, [setConnectionStatus]);

  let pageNode;
  if (connectionStatus === 'closed') {
    pageNode = <ConnectionClosedContent onReconnect={onReconnect} />;
  } else if (connectionStatus === 'error') {
    pageNode = <ConnectionErrorContent onReconnect={onReconnect} />;
  } else {
    pageNode = (
      <GameRoomContent
        encryptedToken={encryptedToken}
        onConnectionError={onConnectionError}
        onConnectionClosed={onConnectionClosed}
      />
    );
  }

  return <PageBackgroundContainer>{pageNode}</PageBackgroundContainer>;
}

const PageBackgroundContainer = styled('div')`
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.customPalette.page.background};
`;
