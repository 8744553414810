import type { Mason, QueryMason } from './definition';

const INVALID_MASON_TAG = 'InvalidMason';
export type InvalidMason = Mason<typeof INVALID_MASON_TAG, {}>;
export function isInvalidMason(mason: Mason): mason is InvalidMason {
  return mason.type === INVALID_MASON_TAG;
}

const SUCCESS_MASON_TAG = 'Success';
export type Success = QueryMason<typeof SUCCESS_MASON_TAG, {}>;
export function isSuccess(mason: Mason): mason is Success {
  return mason.type === SUCCESS_MASON_TAG;
}

const FAILURE_MASON_TAG = 'Failure';
export type Failure = QueryMason<
  'Failure',
  {
    reasons: string[];
  }
>;
export function isFailure(mason: Mason): mason is Failure {
  return mason.type === FAILURE_MASON_TAG;
}
