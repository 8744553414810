import { StoreType, observeStore } from './store';
import { isEqual } from 'lodash-es';
import type { JoinerRoomState } from '../features/joiner/joinerSlice';

/**
 * Connects the relevants parts of the redux store to `window.localStorage`
 * and `window.sessionStorage`.
 * @param store The redux store to configure
 */
export function configureReduxStorage(store: StoreType) {
  configureJoinerState(store);
}

const JOINER_STATE_KEY = 'joiner';

export function getJoinerRoomStateFromStorage(): JoinerRoomState | null {
  const storedState = window.sessionStorage.getItem(JOINER_STATE_KEY);
  if (storedState == null) {
    return null;
  }
  return JSON.parse(storedState) as JoinerRoomState;
}

function configureJoinerState(store: StoreType) {
  observeStore(
    store,
    (state): JoinerRoomState | null => {
      const room = state.joiner.room;
      if (room.loadingStatus === 'complete') {
        return room.data;
      }
      return null;
    },
    (previousJoinerState, currentJoinerState) => {
      if (!isEqual(previousJoinerState, currentJoinerState)) {
        window.sessionStorage.setItem(
          JOINER_STATE_KEY,
          JSON.stringify(currentJoinerState)
        );
      }
    }
  );
}
