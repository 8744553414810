import { styled } from '@mui/material/styles';
import { Paper, PaperProps } from '@mui/material';

type OutlinedContentProps = Omit<PaperProps, 'variant' | 'elevation'>;

export function OutlinedContent(props: OutlinedContentProps) {
  return <OutlinedContentPaper {...props} variant="outlined" />;
}

const OutlinedContentPaper = styled(Paper)`
  background-color: ${({ theme }) => theme.palette.background.default};
`;
