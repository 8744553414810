import tinycolor, { TinyColor } from '@ctrl/tinycolor';

type DOMColor = string;
type ColorInstance = TinyColor;

export function color(colorString: DOMColor): ColorInstance {
  return tinycolor(colorString);
}

export function lighten(
  colorInstance: ColorInstance,
  brightenFactor: number
): ColorInstance {
  return colorInstance.brighten(brightenFactor * 100);
}

export function isLight(colorInstance: ColorInstance): boolean {
  return colorInstance.isLight();
}

export function darken(
  colorInstance: ColorInstance,
  darkenFactor: number
): ColorInstance {
  return colorInstance.darken(darkenFactor * 100);
}

export function isDark(colorInstance: ColorInstance): boolean {
  return colorInstance.isDark();
}

export function toHexString(colorInstance: ColorInstance): DOMColor {
  return colorInstance.toHexString();
}
