import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import invariant from 'invariant';
import { last } from 'lodash-es';
import { IconButton, Button, Tooltip, Badge } from '@mui/material';
import {
  FastRewind as FastRewindIcon,
  FastForward as FastForwardIcon,
  SkipPrevious as SkipPreviousIcon,
  SkipNext as SkipNextIcon,
  History as HistoryIcon,
} from '@mui/icons-material';
import { selectTimeMachine, selectIsCurrentClientTurn } from '../roomSelectors';
import { AppDispatch } from '../../../app/store';
import { chooseTimeMachineSnapshotSelectedIndex } from '../roomSlice';

const FAST_FORWARD_DISTANCE = 5;
const SKIP_FORWARD_DISTANCE = 1;

type TimeMachineProps = {
  className?: string;
};
/**
 * A set of controls that allow navigation through the history of moves in a game.
 */
export function TimeMachineNavigation({ className }: TimeMachineProps) {
  const dispatch = useDispatch<AppDispatch>();

  const timeMachine = useSelector(selectTimeMachine);

  const isMyTurn = useSelector(selectIsCurrentClientTurn);

  let fastRewindNode;
  let skipPreviousNode;
  let skipNextNode;
  let fastForwardNode;
  let controlNode;

  if (timeMachine == null || timeMachine.snapshots.length === 0) {
    fastRewindNode = (
      <IconButton size="small" disabled={true}>
        <FastRewindIcon />
      </IconButton>
    );
    skipPreviousNode = (
      <IconButton size="small" disabled={true}>
        <SkipPreviousIcon />
      </IconButton>
    );
    skipNextNode = (
      <IconButton size="small" disabled={true}>
        <SkipNextIcon />
      </IconButton>
    );
    fastForwardNode = (
      <IconButton size="small" disabled={true}>
        <FastForwardIcon />
      </IconButton>
    );
    controlNode = (
      <Button color="primary" variant="contained" disabled={true}>
        <HistoryIcon />
      </Button>
    );
  } else {
    invariant(timeMachine != null, 'Time machine snapshots should exist');
    const lastSnapshot = last(timeMachine.snapshots);
    invariant(
      lastSnapshot != null,
      'Time machine snapshots should not be empty'
    );

    const callbackForMove = (distance: number) => () => {
      // TODO: really need to test this
      invariant(
        timeMachine.snapshots.length > 0,
        'Tried to move without any snapshots present'
      );
      const currentIndex =
        timeMachine.selectedIndex ?? timeMachine.snapshots.length;
      const destinationIndex = Math.min(
        Math.max(0, currentIndex + distance),
        timeMachine.snapshots.length - 1
      );
      if (
        timeMachine.selectedIndex == null ||
        currentIndex !== destinationIndex
      ) {
        dispatch(
          chooseTimeMachineSnapshotSelectedIndex({ index: destinationIndex })
        );
      }
    };

    const disableRewindIndices = [0, null];
    const disableForwardIndices = [timeMachine.snapshots.length - 1, null];

    fastRewindNode = (
      <IconButton
        size="small"
        onClick={callbackForMove(-FAST_FORWARD_DISTANCE)}
        disabled={disableRewindIndices.includes(timeMachine.selectedIndex)}
      >
        <FastRewindIcon />
      </IconButton>
    );
    skipPreviousNode = (
      <IconButton
        size="small"
        onClick={callbackForMove(-SKIP_FORWARD_DISTANCE)}
        disabled={disableRewindIndices.includes(timeMachine.selectedIndex)}
      >
        <SkipPreviousIcon />
      </IconButton>
    );
    skipNextNode = (
      <IconButton
        size="small"
        onClick={callbackForMove(SKIP_FORWARD_DISTANCE)}
        disabled={disableForwardIndices.includes(timeMachine.selectedIndex)}
      >
        <SkipNextIcon />
      </IconButton>
    );
    fastForwardNode = (
      <IconButton
        size="small"
        onClick={callbackForMove(FAST_FORWARD_DISTANCE)}
        disabled={disableForwardIndices.includes(timeMachine.selectedIndex)}
      >
        <FastForwardIcon />
      </IconButton>
    );

    const isViewingSnapshot = timeMachine.selectedIndex != null;

    const controlColor = isViewingSnapshot ? 'primary' : 'secondary';

    let controlCallback;
    let controlTooltip;

    if (isViewingSnapshot) {
      controlTooltip = 'Back to game';
      controlCallback = () => {
        dispatch(chooseTimeMachineSnapshotSelectedIndex({ index: null }));
      };
    } else {
      controlTooltip = 'View past moves';
      controlCallback = () => {
        dispatch(
          chooseTimeMachineSnapshotSelectedIndex({
            index: timeMachine.snapshots.length - 1,
          })
        );
      };
    }

    controlNode = (
      <Tooltip title={controlTooltip}>
        <Badge
          color="secondary"
          variant="dot"
          invisible={!(isMyTurn && isViewingSnapshot)}
        >
          <Button
            color={controlColor}
            variant="contained"
            onClick={controlCallback}
          >
            <HistoryIcon />
          </Button>
        </Badge>
      </Tooltip>
    );
  }

  // Wrap in spans so that the padding applies correctly
  return (
    <Container className={className}>
      <span>{fastRewindNode}</span>
      <span>{skipPreviousNode}</span>
      <span>{controlNode}</span>
      <span>{skipNextNode}</span>
      <span>{fastForwardNode}</span>
    </Container>
  );
}

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & > * {
    padding: 0 ${({ theme }) => theme.spacing(1)};
  }
`;
