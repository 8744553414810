import { styled } from '@mui/material/styles';
import { GameViewContainerSkeleton, GameInfoContainerSkeleton } from './layout';

export function GameRoomRouteSuspenseFallback() {
  return (
    <PageBackgroundContainer>
      <GameViewContainerSkeleton />
      <GameInfoContainerSkeleton />
    </PageBackgroundContainer>
  );
}

const PageBackgroundContainer = styled('div')`
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.customPalette.page.background};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
