import { useSelector } from 'react-redux';
import { GlobalGridView } from '../../components/GlobalGridView';
import { useSuperTMasonClient } from '../../messages/superTMasonClient';
import type { ClientPlayTurn } from '../../messages/out';
import type { RootState } from '../../app/rootReducer';
import { LocalGrid, GlobalGrid } from '../../messages/gameState';
import { BeforeStartDisabledOverlay } from './disabledOverlay/BeforeStartDisabledOverlay';
import { TerminatedDisabledOverlay } from './disabledOverlay/TerminatedDisabledOverlay';
import { EndedDisabledOverlay } from './disabledOverlay/EndedDisabledOverlay';
import {
  selectTimeMachineSelectedSnapshot,
  selectIsCurrentClientTurn,
  selectGame,
  selectIsGameBeginPossibleBySelf,
} from './roomSelectors';

const EMPTY_LOCAL_GRID: LocalGrid = [
  [0, 0, 0],
  [0, 0, 0],
  [0, 0, 0],
];
const EMPTY_GLOBAL_GRID: GlobalGrid = [
  [EMPTY_LOCAL_GRID, EMPTY_LOCAL_GRID, EMPTY_LOCAL_GRID],
  [EMPTY_LOCAL_GRID, EMPTY_LOCAL_GRID, EMPTY_LOCAL_GRID],
  [EMPTY_LOCAL_GRID, EMPTY_LOCAL_GRID, EMPTY_LOCAL_GRID],
];

// TODO: move to subfolder
export function GameGlobalGridView() {
  const game = useSelector(selectGame);

  // Only a client who is a player can play moves in the grid
  const isActive = useSelector(selectIsGameBeginPossibleBySelf);

  const isPlayable = useSelector(selectIsCurrentClientTurn);

  // There is no on-going game
  const isDisabled = useSelector((state: RootState) => {
    return state.room.roomState !== 'InGame';
  });

  const disabledOverlaySuggestion = useSelector((state: RootState) => {
    const { roomState } = state.room;
    if (roomState === 'BeforeStart') {
      return <BeforeStartDisabledOverlay />;
    }
    if (roomState === 'Terminated') {
      return <TerminatedDisabledOverlay />;
    }
    if (roomState === 'Ended') {
      return <EndedDisabledOverlay />;
    }
    return null;
  });

  const timeMachineSnapshot = useSelector(selectTimeMachineSelectedSnapshot);

  const masonClient = useSuperTMasonClient();

  if (game == null) {
    // Render an empty grid as "preview".
    // We don't want the grid popping into view randomly
    return (
      <GlobalGridView
        grid={EMPTY_GLOBAL_GRID}
        victoryLayout={EMPTY_LOCAL_GRID}
        limit={null}
        highlightedMove={null}
        isDisabled={true}
        disabledOverlaySuggestion={disabledOverlaySuggestion}
        isActive={false}
        isPlayable={false}
      />
    );
  }

  const onGridClick = (global: [number, number], local: [number, number]) => {
    masonClient.query<ClientPlayTurn>({
      type: 'ClientPlayTurn',
      move: { global, local },
    });
  };

  const isViewingSnapshot = timeMachineSnapshot != null;
  const gameStateToShow = timeMachineSnapshot?.game ?? game.state;
  const victoryLayoutToShow =
    timeMachineSnapshot?.victoryLayout ?? game.victoryLayout;
  const highlightedMove = timeMachineSnapshot?.move ?? null;

  // The grid is disabled, unplayable, inactive if viewing a snapshot
  return (
    <GlobalGridView
      grid={gameStateToShow.board}
      victoryLayout={victoryLayoutToShow}
      limit={gameStateToShow.limit}
      highlightedMove={highlightedMove}
      isDisabled={isDisabled || isViewingSnapshot}
      shouldShowDisabledOverlay={!isViewingSnapshot}
      disabledOverlaySuggestion={disabledOverlaySuggestion}
      isActive={isActive && !isViewingSnapshot}
      isPlayable={isPlayable && !isViewingSnapshot}
      onClick={onGridClick}
    />
  );
}
