import { useMemo, useEffect, createContext, useContext } from 'react';
import { GenericMasonClient, Mason } from '../modules/mason';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import type { Subscription } from 'rxjs';
import invariant from 'invariant';

export type MasonClientToken = string;

const SUPERT_WEBSOCKET_URI = process.env.REACT_APP_SUPERT_WEBSOCKET_URI;

export class SuperTMasonClient extends GenericMasonClient {
  private readonly _webSocket$: WebSocketSubject<Mason>;
  private _subscription: Subscription | undefined;

  constructor(token: MasonClientToken) {
    const socket$ = webSocket<Mason>(`${SUPERT_WEBSOCKET_URI}?token=${token}`);
    super(socket$, socket$);
    this._webSocket$ = socket$;
  }

  public connect(onError: (err: any) => void, onComplete: () => void): void {
    invariant(
      this._subscription == null,
      'Tried to create new connection before terminating existing connection'
    );
    this._subscription = this._webSocket$.subscribe({
      error: onError,
      complete: onComplete
    });
  }

  public disconnect(): void {
    invariant(
      this._subscription != null,
      'Tried to disconnect without creating a connection first'
    );
    this._subscription.unsubscribe();
    this._subscription = undefined;
  }
}

// TODO: not a semantic guarantee
// https://reactjs.org/docs/hooks-reference.html#usememo
const useOnce = useMemo;

export function useCreateSuperTMasonClient(
  token: MasonClientToken,
  onError: (err: any) => void,
  onComplete: () => void
): SuperTMasonClient {
  const masonClient = useOnce(() => new SuperTMasonClient(token), [token]);
  useEffect(() => {
    masonClient.connect(onError, onComplete);
    return () => {
      masonClient.disconnect();
    };
  }, [masonClient, onError, onComplete]);
  return masonClient;
}

export const SuperTMasonClientContext = createContext<SuperTMasonClient | null>(
  null
);

export function useSuperTMasonClient(): SuperTMasonClient {
  const masonClient = useContext(SuperTMasonClientContext);
  invariant(
    masonClient != null,
    'No SuperTMasonClientContext.Provider found in component tree'
  );
  return masonClient;
}
