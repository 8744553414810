import type { AppDispatch } from '../../app/store';
import type { InMessage } from '../../messages/in';

import {
  peerConnected,
  peerDisconnected,
  displayNameChanged,
  welcome,
  gamePlayersSync,
  gameStateSync,
  gamePlayedTurn,
  gameLocalVictory,
  gameRoomStateSync,
  gameStarted,
  gameReset,
  gameCompleted,
} from './roomSlice';
import type { MasonClient } from '../../modules/mason';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

function dispatchActionForMessage(dispatch: AppDispatch, message: InMessage) {
  switch (message.type) {
    case 'Composite':
      for (const part of message.parts) {
        dispatchActionForMessage(dispatch, part);
      }
      break;
    case 'ClientPeerConnected':
      dispatch(peerConnected(message));
      break;
    case 'ClientPeerDisconnected':
      dispatch(peerDisconnected(message));
      break;
    case 'ClientDisplayNameChanged':
      dispatch(displayNameChanged(message));
      break;
    case 'ClientWelcome':
      dispatch(welcome(message));
      break;
    case 'GamePlayersSync':
      dispatch(gamePlayersSync(message));
      break;
    case 'GameStateSync':
      dispatch(gameStateSync(message));
      break;
    case 'GamePlayedTurn':
      dispatch(gamePlayedTurn(message));
      break;
    case 'GameLocalVictory':
      dispatch(gameLocalVictory(message));
      break;
    case 'GameRoomStateSync':
      dispatch(gameRoomStateSync(message));
      break;
    case 'GameCompleted':
      dispatch(gameCompleted(message));
      break;
    case 'GameStarted':
      dispatch(gameStarted(message));
      break;
    case 'GameReset':
      dispatch(gameReset(message));
      break;
    default:
      console.warn('Unknown message', message);
  }
}

export function useDispatchActionForIncomingMason(masonClient: MasonClient) {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    const subscription = masonClient.masons$.subscribe({
      next: (mason) => dispatchActionForMessage(dispatch, mason as InMessage),
    });
    return () => subscription.unsubscribe();
  }, [masonClient, dispatch]);
}
