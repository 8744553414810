import { GenericErrorCard } from './GenericErrorCard';

type ConnectionClosedContentProps = {
  onReconnect: () => void;
};
export function ConnectionClosedContent({
  onReconnect,
}: ConnectionClosedContentProps) {
  return (
    <GenericErrorCard
      title="Oh noes..."
      errorDetails={<>The connection to the game was lost.</>}
      onReconnect={onReconnect}
    />
  );
}
