import { styled } from '@mui/material/styles';
import { LocalGrid, EMPTY_BLOCK } from '../messages/gameState';
import {
  chooseBlockComponentForPlayer,
  choosePlayerColorFromTheme,
  getBlockBackgroundColorFromTheme,
} from './blocks';
import { noop, isEqual } from 'lodash-es';
import useTheme from '@mui/styles/useTheme';
import { BlockHighlighter } from './BlockHighlighter';

type Props = {
  grid: LocalGrid;
  onClick?: (row: number, column: number) => void;
  highlightedMove: [number, number] | null;
  /**
   * Whether or not there is no game taking place at all
   */
  isDisabled: boolean;
  /**
   * Whether or not a move is valid in this grid
   */
  isActive: boolean;
  /**
   * Whether or not it is this client's turn right now
   */
  isPlayable: boolean;
};

export function LocalGridView(props: Props) {
  return (
    <StyledGrid>
      <BlockView {...props} row={0} column={0} />
      <BlockView {...props} row={0} column={1} />
      <BlockView {...props} row={0} column={2} />
      <BlockView {...props} row={1} column={0} />
      <BlockView {...props} row={1} column={1} />
      <BlockView {...props} row={1} column={2} />
      <BlockView {...props} row={2} column={0} />
      <BlockView {...props} row={2} column={1} />
      <BlockView {...props} row={2} column={2} />
    </StyledGrid>
  );
}

const LOCAL_BLOCK_SIZE = '75%';

type BlockProps = Props & {
  row: number;
  column: number;
};
function BlockView({
  grid,
  onClick,
  highlightedMove,
  isDisabled,
  isActive,
  isPlayable,
  row,
  column,
}: BlockProps) {
  const blockType = grid[row][column];
  const theme = useTheme();
  const isHighlighted = isEqual(highlightedMove, [row, column]);

  let blockNode;
  if (blockType === EMPTY_BLOCK) {
    blockNode = (
      <StyledEmptyBlock
        isDisabled={isDisabled}
        isPlayable={isPlayable}
        isActive={isActive}
        onClick={isPlayable ? () => onClick?.(row, column) : noop}
      />
    );
  } else {
    const BlockComponent = chooseBlockComponentForPlayer(blockType);
    blockNode = (
      <BlockComponent
        width={LOCAL_BLOCK_SIZE}
        height={LOCAL_BLOCK_SIZE}
        stroke={choosePlayerColorFromTheme(theme, blockType, isDisabled)}
      />
    );
  }
  return (
    <StyledBlock
      isDisabled={isDisabled}
      isActive={isActive}
      isPlayable={isPlayable}
    >
      {isHighlighted ? (
        <BlockHighlighter
          isDisabled={isDisabled}
          isActive={isActive}
          isPlayable={isPlayable}
        >
          {blockNode}
        </BlockHighlighter>
      ) : (
        blockNode
      )}
    </StyledBlock>
  );
}

type StyledBlockProps = {
  isDisabled: boolean;
  isActive: boolean;
  isPlayable: boolean;
};
const StyledBlock = styled('div')<StyledBlockProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${getBlockBackgroundColorFromTheme};
  border-color: ${({ theme }): string => theme.customPalette.grid.border.local};
  border-style: solid;
  border-width: 0 1px 1px 0;
  &:nth-of-type(3n) {
    border-right-width: 0;
  }
  &:nth-of-type(n + 7) {
    border-bottom-width: 0;
  }
`;

const StyledGrid = styled('div')`
  display: grid;
  grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  width: 100%;
  height: 100%;
`;

type StyledEmptyBlockProps = {
  isDisabled: boolean;
  isPlayable: boolean;
  isActive: boolean;
};
const StyledEmptyBlock = styled('div')<StyledEmptyBlockProps>`
  width: 100%;
  height: 100%;
  cursor: ${({ isDisabled, isPlayable, isActive }) =>
    !isDisabled && isPlayable && isActive ? 'pointer' : 'inherit'};
`;
