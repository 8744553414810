import {
  createAsyncThunk,
  createSlice,
  ActionReducerMapBuilder,
} from '@reduxjs/toolkit';

import {
  joinGameRoomAPI,
  JoinGameRoomResponse,
  CreateGameRoomResponse,
  createGameRoomAPI,
} from '../../common/httpClient';
import type { AsyncLoadedState } from '../../modules/commons/asyncState';
import type { RootState } from '../../app/rootReducer';
import { getJoinerRoomStateFromStorage } from '../../app/reduxStorage';

export type JoinerRoomState = {
  name: string;
  encryptedToken: string;
};

export type JoinerRoomAsyncLoadedState = AsyncLoadedState<
  JoinerRoomState,
  { errorMessage: string }
>;

export type JoinerSliceState = {
  room: JoinerRoomAsyncLoadedState;
};

const initialState: JoinerSliceState = {
  room: getInitialJoinerRoomState(),
};
/**
 * Load room state from session storage, if available.
 */
function getInitialJoinerRoomState(): AsyncLoadedState<JoinerRoomState, never> {
  const storedState = getJoinerRoomStateFromStorage();
  return storedState != null
    ? { loadingStatus: 'complete', data: storedState }
    : { loadingStatus: 'none' };
}

export const createGameRoom = createAsyncThunk<
  CreateGameRoomResponse,
  { gameRoomName?: string; displayName?: string },
  { state: RootState }
>('joiner/createGameRoom', ({ gameRoomName, displayName }) =>
  createGameRoomAPI(gameRoomName, displayName)
);
function createGameRoomReducers(
  builder: ActionReducerMapBuilder<JoinerSliceState>
) {
  builder
    .addCase(createGameRoom.pending, (state, _) => {
      state.room = {
        loadingStatus: 'loading',
      };
    })
    .addCase(createGameRoom.fulfilled, (state, action) => {
      state.room = {
        loadingStatus: 'complete',
        data: {
          name: action.payload.gameRoomName,
          encryptedToken: action.payload.token,
        },
      };
    })
    .addCase(createGameRoom.rejected, (state, _) => {
      state.room = {
        loadingStatus: 'error',
        error: {
          // Get error from action
          errorMessage: 'Unknown',
        },
      };
    });
}

export const joinGameRoom = createAsyncThunk<
  JoinGameRoomResponse,
  string,
  { state: RootState }
>('joiner/joinGameRoom', (gameRoomName: string) =>
  joinGameRoomAPI(gameRoomName)
);
function joinGameRoomReducers(
  builder: ActionReducerMapBuilder<JoinerSliceState>
) {
  builder
    .addCase(joinGameRoom.pending, (state, _) => {
      state.room = {
        loadingStatus: 'loading',
      };
    })
    .addCase(joinGameRoom.fulfilled, (state, action) => {
      state.room = {
        loadingStatus: 'complete',
        data: {
          name: action.meta.arg,
          encryptedToken: action.payload.token,
        },
      };
    })
    .addCase(joinGameRoom.rejected, (state, _) => {
      state.room = {
        loadingStatus: 'error',
        error: {
          // Get error from action
          errorMessage: 'Unknown',
        },
      };
    });
}

const joinerSlice = createSlice({
  name: 'joiner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createGameRoomReducers(builder);
    joinGameRoomReducers(builder);
  },
});

export default joinerSlice.reducer;
