import { useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createDarkTheme } from './themes';
import { GameRoomRoute } from '../features/room/gameRoomRoute/GameRoomRoute';
import { JoinerRoute } from '../features/joiner/JoinerRoute';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { GAME_ROOM_ROUTE, HOME_ROUTE } from './routes';
import CssBaseline from '@mui/material/CssBaseline';
import { NotFoundPage } from './NotFoundPage';

export default function RootApp() {
  const theme = useMemo(() => createDarkTheme(), []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        <Switch>
          <Route path={HOME_ROUTE.path} exact={true}>
            <JoinerRoute />
          </Route>
          <Route path={GAME_ROOM_ROUTE.path}>
            <GameRoomRoute />
          </Route>
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}
