import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../../app/rootReducer';
import type { JoinerSliceState, JoinerRoomAsyncLoadedState } from './joinerSlice';

function selectJoinerSlice(state: RootState): JoinerSliceState {
  return state.joiner;
}

export const selectJoinerRoom = createSelector(
  selectJoinerSlice,
  (joiner): JoinerRoomAsyncLoadedState => joiner.room
);
