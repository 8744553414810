import type { DefaultTheme } from '@mui/styles';
import { createTheme as createMuiTheme } from '@mui/material';

export function createDarkTheme(): DefaultTheme {
  const muiTheme = createMuiTheme({
    // See:
    //   * https://mui.com/customization/palette/
    //   * https://mui.com/customization/color/#tools-by-the-community
    palette: {
      mode: 'dark',
      primary: {
        main: '#d53333',
      },
      secondary: {
        main: '#ff80ab',
      },
      background: {
        default: '#303030',
        paper: '#424242',
      },
    },
  });

  return {
    ...muiTheme,
    customPalette: {
      block: {
        enabled: {
          x: '#030303',
          o: '#030303',
        },
        disabled: {
          x: '#ff896b',
          o: '#ff896b',
        },
        preview: {
          x: '#ff896b',
          o: '#ff896b',
        },
      },
      grid: {
        border: {
          local: 'black',
          global: 'black',
        },
        background: {
          enabled: {
            active: {
              playable: '#dcdcdc',
              unplayable: '#b3474e',
            },
            inactive: '#706c61',
            won: '#f6eec7',
          },
          disabled: '#333333',
        },
      },
      page: {
        background: '#030303',
      },
    },
  };
}
