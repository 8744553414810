import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
  selectPlayerClients,
  selectSelfClient,
  selectConnectedClients,
} from '../roomSelectors';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  ListItemSecondaryAction,
  ListSubheader,
} from '@mui/material';
import { Star as StarIcon } from '@mui/icons-material';
import type { ClientInfo } from '../roomSlice';
import useTheme from '@mui/styles/useTheme';
import {
  chooseBlockComponentForPlayer,
  choosePlayerPreviewColorFromTheme,
} from '../../../components/blocks';
import {
  PLAYER_ONE_BLOCK,
  PLAYER_TWO_BLOCK,
} from '../../../messages/gameState';
import { partition } from 'lodash-es';

type ClientsViewProps = {
  className?: string;
};
/**
 * A list of all connected clients in a game room as a list.
 */
export function ClientsView({ className }: ClientsViewProps) {
  const self = useSelector(selectSelfClient);
  const connectedClients = useSelector(selectConnectedClients);

  // always put myself at the top
  const [selfPartition, othersPartition] = partition(
    connectedClients,
    (client) => client.id === self?.id
  );
  const orderedClients = [...selfPartition, ...othersPartition];

  return (
    <StyledPaper variant="outlined" className={className}>
      <StyledList
        subheader={<StyledListSubheader>Everyone</StyledListSubheader>}
      >
        {orderedClients.map((client) => (
          <ClientListItem key={client.id} {...client} />
        ))}
      </StyledList>
    </StyledPaper>
  );
}

function ClientListItem({ id, displayName }: ClientInfo) {
  const theme = useTheme();
  const self = useSelector(selectSelfClient);
  const playerClients = useSelector(selectPlayerClients);

  const isMe = id === self?.id;
  const playerNumber =
    1 + playerClients.findIndex((player) => player?.id === id);

  let playerIconNode;
  const PLAYER_ICON_SIZE = theme.spacing(2);
  switch (playerNumber) {
    case 1:
      const PlayerOneBlock = chooseBlockComponentForPlayer(PLAYER_ONE_BLOCK);
      playerIconNode = (
        <PlayerOneBlock
          stroke={choosePlayerPreviewColorFromTheme(theme, PLAYER_ONE_BLOCK)}
          width={PLAYER_ICON_SIZE}
          height={PLAYER_ICON_SIZE}
        />
      );
      break;
    case 2:
      const PlayerTwoBlock = chooseBlockComponentForPlayer(PLAYER_TWO_BLOCK);
      playerIconNode = (
        <PlayerTwoBlock
          stroke={choosePlayerPreviewColorFromTheme(theme, PLAYER_TWO_BLOCK)}
          width={PLAYER_ICON_SIZE}
          height={PLAYER_ICON_SIZE}
        />
      );
      break;
    default:
      playerIconNode = null;
      break;
  }

  const clientDisplayNode = (
    <span>
      <>{displayName}</>
      {playerNumber > 0 && (
        <>
          {' '}
          (<b>P{playerNumber}</b>)
        </>
      )}
    </span>
  );

  return (
    <ListItem key={id} dense={true}>
      <StyledListItemIcon>
        {isMe ? <StyledStarIcon /> : <></>}
      </StyledListItemIcon>
      <ListItemText>{clientDisplayNode}</ListItemText>
      {playerIconNode && (
        <StyledListItemSecondaryAction>
          {playerIconNode}
        </StyledListItemSecondaryAction>
      )}
    </ListItem>
  );
}

const StyledPaper = styled(Paper)`
  margin: ${({ theme }) => theme.spacing(2)};
`;

const StyledList = styled(List)`
  width: 100%;
`;
const StyledListSubheader = styled(ListSubheader)`
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: ${({ theme }) => theme.spacing(4)};
`;

const StyledStarIcon = styled(StarIcon)`
  color: yellow;
`;

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)`
  display: flex;
  align-items: center;
`;
