import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { last } from 'lodash-es';
import { Typography } from '@mui/material';
import {
  selectTimeMachineSelectedSnapshot,
  selectTimeMachineSnapshots,
  selectClientsById,
} from '../roomSelectors';
import { PlayerMove, PLAYER_ONE_BLOCK } from '../../../messages/gameState';
import { CodeText } from '../../../components/CodeText';

type LastMoveViewProps = {
  className?: string;
};

export function TimeMachineLastMoveView({ className }: LastMoveViewProps) {
  const byId = useSelector(selectClientsById);

  const selectedSnapshot = useSelector(selectTimeMachineSelectedSnapshot);
  const allSnapshots = useSelector(selectTimeMachineSnapshots);
  const lastSnapshot = last(allSnapshots) ?? null;

  const snapshotToShow = selectedSnapshot ?? lastSnapshot;
  if (snapshotToShow == null) {
    return (
      <Container className={className}>
        <PlayerNameTypography variant="caption" align="center" noWrap={true}>
          by <i>no one</i>
        </PlayerNameTypography>
        <Typography variant="h3">∅</Typography>
        <Typography variant="caption">last move</Typography>
      </Container>
    );
  }
  const { player, turn, move } = snapshotToShow;

  const playerNumber = turn === PLAYER_ONE_BLOCK ? 1 : 2;

  return (
    <Container className={className}>
      <PlayerNameTypography variant="caption" align="center" noWrap={true}>
        by {byId[player].displayName} {`(P${playerNumber})`}
      </PlayerNameTypography>
      <Typography variant="h3">
        <CodeText>{playerMoveToShortMove(move)}</CodeText>
      </Typography>
      <Typography variant="caption">
        {selectedSnapshot != null ? 'move' : 'last move'}
      </Typography>
    </Container>
  );
}

const SHORT_ROW_NAMES = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
const SHORT_COLUMN_NAMES = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];

function playerMoveToShortMove(move: PlayerMove): string {
  const rowIndex = move.global[0] * 3 + move.local[0];
  const columnIndex = move.global[1] * 3 + move.local[1];
  return `${SHORT_COLUMN_NAMES[columnIndex]}${SHORT_ROW_NAMES[rowIndex]}`;
}

const Container = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
`;

const PLAYER_NAME_WIDTH = 150;

const PlayerNameTypography = styled(Typography)`
  width: ${PLAYER_NAME_WIDTH}px;
`;
