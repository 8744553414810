import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router';
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  CardHeader,
} from '@mui/material';
import { HOME_ROUTE } from '../../../app/routes';
import genericErrorLogo from './sad-cat.png';

const IMG_WIDTH = 166;
const IMG_HEIGHT = 267;

type GenericErrorCardProps = {
  title: string;
  errorDetails: React.ReactNode;
  onReconnect?: () => void;
};
export function GenericErrorCard({
  title,
  errorDetails,
  onReconnect,
}: GenericErrorCardProps) {
  const history = useHistory();
  const onLeave = () => {
    history.push(HOME_ROUTE.buildPath({}));
  };

  return (
    <Container>
      <Card variant="outlined">
        <CardHeader title={title} />
        <StyledCardContent>
          <img
            src={genericErrorLogo}
            alt="Error"
            width={IMG_WIDTH}
            height={IMG_HEIGHT}
          />
          <StyledTypography variant="body1">{errorDetails}</StyledTypography>
        </StyledCardContent>
        <StyledCardActions>
          <Button disabled={onReconnect == null} onClick={onReconnect}>
            Reconnect
          </Button>
          <Button
            onClick={onLeave}
            color="primary"
            variant="contained"
            disableElevation={true}
          >
            Leave
          </Button>
        </StyledCardActions>
      </Card>
    </Container>
  );
}

const Container = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCardContent = styled(CardContent)`
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCardActions = styled(CardActions)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledTypography = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;
