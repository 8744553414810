import './polyfills';

import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { Provider as ReduxStoreProvider } from 'react-redux';

import store from './app/store';
import { configureReduxStorage } from './app/reduxStorage';

configureReduxStorage(store);

const render = () => {
  const RootApp = require('./app/RootApp').default;
  ReactDOM.render(
    <StrictMode>
      <ReduxStoreProvider store={store}>
        <RootApp />
      </ReduxStoreProvider>
    </StrictMode>,
    document.getElementById('root')
  );
};
render();
