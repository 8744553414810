import { styled, css } from '@mui/material/styles';
import {
  GlobalGrid,
  LocalGrid,
  EMPTY_BLOCK,
  PlayerMove,
} from '../messages/gameState';
import { LocalGridView } from './LocalGridView';
import { isEqual } from 'lodash-es';
import {
  chooseBlockComponentForPlayer,
  choosePlayerColorFromTheme,
} from './blocks';
import useTheme from '@mui/styles/useTheme';
import { BlockHighlighter } from './BlockHighlighter';

type Props = {
  grid: GlobalGrid;
  victoryLayout: LocalGrid;
  limit: [number, number] | null;
  highlightedMove: PlayerMove | null;
  /**
   * Whether or not there is no game taking place at all
   */
  isDisabled: boolean;
  /**
   * Whether or not to show an overlay that hides the grid if it's disabled
   */
  shouldShowDisabledOverlay?: boolean;
  /**
   * UI to show when a disabled grid has an overlay
   */
  disabledOverlaySuggestion?: React.ReactNode;
  /**
   * Whether or not a move is valid in this grid
   */
  isActive: boolean;
  /**
   * Whether or not it is this client's turn right now
   */
  isPlayable: boolean;
  onClick?: (global: [number, number], local: [number, number]) => void;
};

const ALL_GRID_COORDINATES: Array<[number, number]> = [
  [0, 0],
  [0, 1],
  [0, 2],
  [1, 0],
  [1, 1],
  [1, 2],
  [2, 0],
  [2, 1],
  [2, 2],
];

const GLOBAL_BLOCK_SIZE = '75%';

export function GlobalGridView({
  grid: globalGrid,
  victoryLayout,
  limit,
  highlightedMove,
  isDisabled,
  shouldShowDisabledOverlay = true,
  disabledOverlaySuggestion,
  isActive,
  isPlayable,
  onClick,
}: Props) {
  const theme = useTheme();

  function chooseVictoryBlock(row: number, column: number) {
    const victoryBlock = victoryLayout[row][column];
    if (victoryBlock === EMPTY_BLOCK) {
      return null;
    }
    const BlockComponent = chooseBlockComponentForPlayer(victoryBlock);
    const isHighlighted = isEqual(highlightedMove?.global, [row, column]);
    const blockNode = (
      <BlockComponent
        width={GLOBAL_BLOCK_SIZE}
        height={GLOBAL_BLOCK_SIZE}
        stroke={choosePlayerColorFromTheme(theme, victoryBlock, isDisabled)}
      />
    );
    return (
      <StyledWinningBlockContainer isDisabled={isDisabled}>
        {isHighlighted ? (
          <BlockHighlighter
            isDisabled={isDisabled}
            isActive={isActive}
            isPlayable={isPlayable}
          >
            {blockNode}
          </BlockHighlighter>
        ) : (
          blockNode
        )}
      </StyledWinningBlockContainer>
    );
  }

  return (
    <StyledGridBorder isDisabled={isDisabled}>
      <DisabledOverlayContainer
        isDisabled={isDisabled && shouldShowDisabledOverlay}
      >
        <StyledGrid isDisabled={isDisabled}>
          {ALL_GRID_COORDINATES.map((coordinate) => {
            const [row, column] = coordinate;

            const isLocalGridActive =
              isActive && (limit == null || isEqual(limit, coordinate));

            const localHighlightedMove =
              highlightedMove != null &&
              isEqual(highlightedMove.global, coordinate)
                ? highlightedMove.local
                : null;

            return (
              <StyledLocalGridContainer key={`${row}-${column}`}>
                {chooseVictoryBlock(row, column) ?? (
                  <LocalGridView
                    grid={globalGrid[row][column]}
                    highlightedMove={localHighlightedMove}
                    isDisabled={isDisabled}
                    isActive={isLocalGridActive}
                    isPlayable={isPlayable}
                    onClick={(localRow, localColumn) =>
                      onClick?.(coordinate, [localRow, localColumn])
                    }
                  />
                )}
              </StyledLocalGridContainer>
            );
          })}
        </StyledGrid>
      </DisabledOverlayContainer>
      {isDisabled && shouldShowDisabledOverlay && (
        <DisabledOverlaySuggestionContainer>
          {disabledOverlaySuggestion}
        </DisabledOverlaySuggestionContainer>
      )}
    </StyledGridBorder>
  );
}

const StyledLocalGridContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: ${({ theme }): string =>
    theme.customPalette.grid.border.global};
  border-style: solid;
  border-width: 0 5px 5px 0;
  &:nth-of-type(3n) {
    border-right-width: 0;
  }
  &:nth-of-type(n + 7) {
    border-bottom-width: 0;
  }
`;

const StyledGridBorder = styled('div')<{ isDisabled: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;

  ${({ theme, isDisabled }) => css`
    border: 2mm ridge
      ${isDisabled ? theme.palette.divider : theme.palette.primary.main};
    filter: ${isDisabled ? 'none' : 'drop-shadow(0 8px 8px black)'};
  `}
`;

const DisabledOverlayContainer = styled('div')<{ isDisabled: boolean }>`
  width: 100%;
  height: 100%;
  transition: ${({ theme }) =>
    `${theme.transitions.duration.shortest}ms filter ${theme.transitions.easing.easeOut}`};
  filter: ${({ isDisabled }) =>
    isDisabled ? 'blur(2px) brightness(10%)' : 'none'};
`;

const DisabledOverlaySuggestionContainer = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledGrid = styled('div')<{
  isDisabled: boolean;
}>`
  display: grid;
  grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  width: 100%;
  height: 100%;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'auto')};
`;

const StyledWinningBlockContainer = styled('div')<{ isDisabled: boolean }>`
  background-color: ${({ theme, isDisabled }): string => {
    const { background } = theme.customPalette.grid;
    return isDisabled ? background.disabled : background.enabled.won;
  }};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
