import { useHistory } from 'react-router';
import { styled } from '@mui/material/styles';
import {
  CardContent,
  Card,
  CardHeader,
  Button,
  CardActions,
  Typography,
} from '@mui/material';
// TODO: use different logo
import notFoundErrorLogo from './sad-cat.png';
import { HOME_ROUTE } from './routes';

const IMG_WIDTH = 166;
const IMG_HEIGHT = 267;

export function NotFoundPage() {
  const history = useHistory();
  const onLeave = () => {
    history.push(HOME_ROUTE.buildPath({}));
  };

  return (
    <PageBackgroundContainer>
      <Container>
        <Card variant="outlined">
          <CardHeader title="Gasp! It's a 404." />
          <StyledCardContent>
            <img
              src={notFoundErrorLogo}
              alt="Error"
              width={IMG_WIDTH}
              height={IMG_HEIGHT}
            />
            <StyledTypography variant="body1">
              You have stumbled into uncharted territory.
            </StyledTypography>
          </StyledCardContent>
          <StyledCardActions>
            <Button
              onClick={onLeave}
              color="primary"
              variant="contained"
              disableElevation={true}
            >
              Go back home
            </Button>
          </StyledCardActions>
        </Card>
      </Container>
    </PageBackgroundContainer>
  );
}

const Container = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCardContent = styled(CardContent)`
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCardActions = styled(CardActions)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

const PageBackgroundContainer = styled('div')`
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.customPalette.page.background};
`;
