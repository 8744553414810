import type { ThunkDispatch } from 'redux-thunk';
import type { Action } from 'redux';
import type { RootState } from '../app/rootReducer';
import { useDispatch } from 'react-redux';

type AppThunkDispatch = ThunkDispatch<RootState, any, Action>;

/**
 * This is a workaround for `useDispatch` not accepting thunks and
 * `AppDispatch` not accepting actions created with `createAsyncThunk`.
 */
export function useThunkDispatch(): AppThunkDispatch {
  return useDispatch<AppThunkDispatch>();
}
