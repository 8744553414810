import type { DefaultTheme } from '@mui/styles';
import {
  PlayerBlock,
  PLAYER_ONE_BLOCK,
  PLAYER_TWO_BLOCK,
} from '../messages/gameState';

type Props = {
  width: number | string;
  height: number | string;
  stroke?: string;
};

const STROKE_WIDTH = 7;

export function BlockX({ width, height, stroke = 'black' }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      preserveAspectRatio="none"
    >
      <line
        x1="5"
        y1="5"
        x2="27"
        y2="27"
        stroke={stroke}
        strokeWidth={STROKE_WIDTH}
        strokeLinecap="round"
      />
      <line
        x1="5"
        y1="27"
        x2="27"
        y2="5"
        stroke={stroke}
        strokeWidth={STROKE_WIDTH}
        strokeLinecap="round"
      />
    </svg>
  );
}

export function BlockO({ width, height, stroke = 'black' }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      preserveAspectRatio="none"
    >
      <circle
        cx="16"
        cy="16"
        r="12"
        fill="transparent"
        stroke={stroke}
        strokeWidth={STROKE_WIDTH}
      />
    </svg>
  );
}

export type BlockComponent = React.ComponentType<Props>;

export function chooseBlockComponentForPlayer(
  player: PlayerBlock
): BlockComponent {
  switch (player) {
    case PLAYER_ONE_BLOCK:
      return BlockX;
    case PLAYER_TWO_BLOCK:
      return BlockO;
  }
}

export function choosePlayerColorFromTheme(
  { customPalette }: DefaultTheme,
  player: PlayerBlock,
  isDisabled: boolean = false
): string {
  const color = isDisabled
    ? customPalette.block.disabled
    : customPalette.block.enabled;
  switch (player) {
    case PLAYER_ONE_BLOCK:
      return color.x;
    case PLAYER_TWO_BLOCK:
      return color.o;
  }
}

export function choosePlayerPreviewColorFromTheme(
  { customPalette }: DefaultTheme,
  player: PlayerBlock
): string {
  const { preview } = customPalette.block;
  switch (player) {
    case PLAYER_ONE_BLOCK:
      return preview.x;
    case PLAYER_TWO_BLOCK:
      return preview.o;
  }
}

type GetBlockBackgroundColorFromThemeProps = {
  theme: DefaultTheme;
  isDisabled: boolean;
  isActive: boolean;
  isPlayable: boolean;
};
export function getBlockBackgroundColorFromTheme({
  theme,
  isDisabled,
  isActive,
  isPlayable,
}: GetBlockBackgroundColorFromThemeProps) {
  const { background } = theme.customPalette.grid;
  if (isDisabled) {
    return background.disabled;
  } else if (isActive) {
    return isPlayable
      ? background.enabled.active.playable
      : background.enabled.active.unplayable;
  } else {
    return background.enabled.inactive;
  }
}
