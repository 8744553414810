import ResizeObserverPolyfill from 'resize-observer-polyfill';

function setupPolyfills(globalObject: any) {
  // Resize observer is needed for the package, `use-resize-observer`.
  // https://caniuse.com/#feat=resizeobserver
  if (typeof globalObject.ResizeObserver === 'undefined') {
    globalObject.ResizeObserver = ResizeObserverPolyfill;
  }
}

setupPolyfills(window);
