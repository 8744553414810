import { combineReducers } from '@reduxjs/toolkit';

import roomReducer from '../features/room/roomSlice';
import joinerReducer from '../features/joiner/joinerSlice';

const rootReducer = combineReducers({
  room: roomReducer,
  joiner: joinerReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
