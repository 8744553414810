import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { BeforeStartStatusBar } from './BeforeStartStatusBar';
import { TerminatedStatusBar } from './TerminatedStatusBar';
import { InGameStatusBar } from './InGameStatusBar';
import { selectRoomState } from '../roomSelectors';
import { EndedStatusBar } from './EndedStatusBar';

export const HEIGHT_PX = 64;

export function GameStatusBar() {
  const roomState = useSelector(selectRoomState);
  let statusBarNode;
  if (roomState === 'BeforeStart') {
    statusBarNode = <BeforeStartStatusBar />;
  } else if (roomState === 'InGame') {
    statusBarNode = <InGameStatusBar />;
  } else if (roomState === 'Terminated') {
    statusBarNode = <TerminatedStatusBar />;
  } else if (roomState === 'Ended') {
    statusBarNode = <EndedStatusBar />;
  }

  return <GameStatusBarContainer>{statusBarNode}</GameStatusBarContainer>;
}

const GameStatusBarContainer = styled('div')`
  width: 100%;
  height: ${HEIGHT_PX}px;
`;
