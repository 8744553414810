import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../../app/rootReducer';
import type {
  RoomSliceState,
  ClientInfo,
  TimeMachineState,
  TimeMachineSnapshot,
  VictoryState,
} from './roomSlice';
import type { ClientId } from '../../messages/client';
import {
  PlayerBlock,
  PLAYER_ONE_BLOCK,
  PLAYER_TWO_BLOCK,
} from '../../messages/gameState';
import { RoomState } from '../../messages/in';

export function selectRoomSlice(state: RootState): RoomSliceState {
  return state.room;
}

export const selectRoomState = createSelector(
  selectRoomSlice,
  (room): RoomState | null => room?.roomState ?? null
);

export const selectGame = createSelector(
  selectRoomSlice,
  (room): RoomSliceState['game'] | null => room.game ?? null
);

export const selectTimeMachine = createSelector(
  selectGame,
  (game): TimeMachineState | null => game?.timeMachine ?? null
);

export const selectTimeMachineSnapshots = createSelector(
  selectTimeMachine,
  (timeMachine): TimeMachineSnapshot[] => timeMachine?.snapshots ?? []
);

export const selectTimeMachineSelectedSnapshot = createSelector(
  selectTimeMachine,
  (timeMachine): TimeMachineSnapshot | null => {
    if (timeMachine?.selectedIndex == null) {
      return null;
    }
    return timeMachine.snapshots[timeMachine.selectedIndex];
  }
);

export const selectVictory = createSelector(
  selectGame,
  (game): VictoryState | null => game?.victory ?? null
);

export const selectClientsById = createSelector(
  selectRoomSlice,
  (room): Record<ClientId, ClientInfo> => room.clients.all.byId
);

export const selectSelfClient = createSelector(
  selectRoomSlice,
  (room): ClientInfo | null => room.clients.self ?? null
);

export const selectConnectedClients = createSelector(
  selectRoomSlice,
  (room): ClientInfo[] => {
    const allClients = room.clients.all;
    return allClients.currentlyConnected.map(
      (clientId) => allClients.byId[clientId]
    );
  }
);

export const selectPlayerClients = createSelector(
  selectRoomSlice,
  selectClientsById,
  (room, clientsById): [ClientInfo | null, ClientInfo | null] => {
    const { players } = room.clients;

    function getPlayerInfo(id: ClientId | null) {
      return id != null ? clientsById[id] : null;
    }
    return [getPlayerInfo(players[0]), getPlayerInfo(players[1])];
  }
);

export const selectArePlayersFilled = createSelector(
  selectRoomSlice,
  (room): boolean => {
    const { players } = room.clients;
    return !players.includes(null);
  }
);

export const selectIsCurrentClientAPlayer = createSelector(
  selectRoomSlice,
  (room): boolean => {
    const { players, self } = room.clients;
    return self?.id != null && players.includes(self?.id);
  }
);

export const selectIsGameBeginPossibleBySelf = createSelector(
  selectArePlayersFilled,
  selectIsCurrentClientAPlayer,
  (arePlayersFilled, isCurrentClientAPlayer): boolean =>
    arePlayersFilled && isCurrentClientAPlayer
);

export const selectCurrentGameTurn = createSelector(
  selectGame,
  (game): PlayerBlock | null => {
    return game?.state.turn ?? null;
  }
);

export const selectIsCurrentClientTurn = createSelector(
  selectRoomSlice,
  selectCurrentGameTurn,
  (room, turn): boolean => {
    const { players, self } = room.clients;
    if (turn == null || self == null) {
      return false;
    }
    return (
      (turn === PLAYER_ONE_BLOCK && players[0] === self.id) ||
      (turn === PLAYER_TWO_BLOCK && players[1] === self.id)
    );
  }
);
