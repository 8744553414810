import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import useTheme from '@mui/styles/useTheme';
import { color, lighten, darken, isLight, toHexString } from '../common/colors';
import { getBlockBackgroundColorFromTheme } from './blocks';

type HighlightContainerProps = React.HTMLAttributes<HTMLDivElement> & {
  isDisabled: boolean;
  isPlayable: boolean;
  isActive: boolean;
};
export function BlockHighlighter({
  isDisabled,
  isPlayable,
  isActive,
  ...htmlProps
}: HighlightContainerProps) {
  const theme = useTheme();

  const [fromColor, toColor] = useMemo(() => {
    const baseColor = color(
      getBlockBackgroundColorFromTheme({
        theme,
        isDisabled,
        isPlayable,
        isActive,
      })
    );
    const isBaseColorLight = isLight(baseColor);
    const transformFunction = isBaseColorLight ? darken : lighten;
    const FACTORS = [0.05, 0.25];
    const fromFactor = (isBaseColorLight ? Math.min : Math.max)(...FACTORS);
    const toFactor = (isBaseColorLight ? Math.max : Math.min)(...FACTORS);

    const from = toHexString(transformFunction(baseColor, fromFactor));
    const to = toHexString(transformFunction(baseColor, toFactor));
    return [from, to];
  }, [theme, isActive, isDisabled, isPlayable]);

  return (
    <HighlightContainerInner
      {...htmlProps}
      backgroundRadialFromColor={fromColor}
      backgroundRadialToColor={toColor}
    />
  );
}

type HighlightContainerInnerProps = {
  backgroundRadialFromColor: string;
  backgroundRadialToColor: string;
};

const HighlightContainerInner = styled('div')<HighlightContainerInnerProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: ${({
    backgroundRadialFromColor,
    backgroundRadialToColor,
  }) =>
    `radial-gradient(circle at center, ${backgroundRadialFromColor}, ${backgroundRadialToColor})`};
`;
