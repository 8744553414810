import invariant from 'invariant';

type ResolveFunc<T> = (value: T | PromiseLike<T>) => void;
type RejectFunc = (reason?: any) => void;

export interface Deferred<T> {
  resolve: ResolveFunc<T>;
  reject: RejectFunc;
  promise: Promise<T>;
}

export function createDeferred<T>(): Deferred<T> {
  let resolveFunc: ResolveFunc<T> | undefined;
  let rejectFunc: RejectFunc | undefined;
  const promise = new Promise<T>((resolve, reject) => {
    resolveFunc = resolve;
    rejectFunc = reject;
  });
  invariant(
    resolveFunc != null && rejectFunc != null,
    'Promise callback should be executed synchronously'
  );
  return {
    resolve: resolveFunc,
    reject: rejectFunc,
    promise,
  };
}
