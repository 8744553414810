export type RouteDefinition<TParams extends {}> = {
  path: string;
  buildPath(params: TParams): string;
};

type ValuesToStringType<TProps> = { [K in keyof TProps]: string };

export type RouteParams<
  TRouteDefinition
> = TRouteDefinition extends RouteDefinition<infer TParams>
  ? ValuesToStringType<TParams>
  : never;

export const HOME_ROUTE: RouteDefinition<{}> = {
  path: '/',
  buildPath: (_ = {}) => '/'
};

type GameRouteParams = {
  gameRoomName: string;
};
export const GAME_ROOM_ROUTE: RouteDefinition<GameRouteParams> = {
  path: '/play/:gameRoomName',
  buildPath: ({ gameRoomName }) => `/play/${gameRoomName}`
};
