import ky from 'ky';

export const superTHttpClient = ky.extend({
  prefixUrl: process.env.REACT_APP_SUPERT_HTTP_URI,
});

export type CreateGameRoomResponse = {
  gameRoomName: string;
  token: string;
};
export function createGameRoomAPI(
  gameRoomName?: string,
  displayName?: string
): Promise<CreateGameRoomResponse> {
  const searchParams = new URLSearchParams();
  if (gameRoomName) {
    searchParams.append('gameRoomName', gameRoomName);
  }
  if (displayName) {
    searchParams.append('displayName', displayName);
  }
  return superTHttpClient
    .post('create-game-room', { searchParams })
    .json<CreateGameRoomResponse>();
}

export type JoinGameRoomResponse = {
  token: string;
};
export function joinGameRoomAPI(
  gameRoomName: string,
  displayName?: string
): Promise<JoinGameRoomResponse> {
  const searchParams = new URLSearchParams();
  searchParams.append('gameRoomName', gameRoomName);
  if (displayName) {
    searchParams.append('displayName', displayName);
  }
  return superTHttpClient
    .post('join-game-room', { searchParams })
    .json<JoinGameRoomResponse>();
}
