import { useSelector, useDispatch } from 'react-redux';
import { styled, keyframes, css } from '@mui/material/styles';
import { PlayersView } from './PlayersView';
import {
  selectPlayerClients,
  selectIsCurrentClientTurn,
  selectTimeMachineSelectedSnapshot,
} from '../roomSelectors';
import {
  Timer as TimerIcon,
  History as HistoryIcon,
} from '@mui/icons-material';
import { Button, Tooltip, Typography, Badge } from '@mui/material';
import { AppDispatch } from '../../../app/store';
import { chooseTimeMachineSnapshotSelectedIndex } from '../roomSlice';

export function InGameStatusBar() {
  const dispatch = useDispatch<AppDispatch>();

  const playerClients = useSelector(selectPlayerClients);
  const isMyTurn = useSelector(selectIsCurrentClientTurn);
  const selectedSnapshot = useSelector(selectTimeMachineSelectedSnapshot);

  // TODO: different view for spectators
  let turnPromptNode;
  if (isMyTurn) {
    const promptText = 'Your Turn';
    if (selectedSnapshot == null) {
      turnPromptNode = <Typography variant="body1">{promptText}</Typography>;
    } else {
      const stopTimeMachine = () => {
        dispatch(chooseTimeMachineSnapshotSelectedIndex({ index: null }));
      };
      turnPromptNode = (
        <Tooltip title="Go back to game">
          <Badge color="secondary" variant="dot">
            <Button
              variant="contained"
              color="primary"
              startIcon={<HistoryIcon />}
              onClick={stopTimeMachine}
            >
              {promptText}
            </Button>
          </Badge>
        </Tooltip>
      );
    }
  } else {
    turnPromptNode = (
      <Typography variant="caption">Waiting for Opponent</Typography>
    );
  }

  return (
    <Container>
      <StyledPlayersView players={playerClients} />
      <TurnInfoContainer>
        <TurnPromptContainer>{turnPromptNode}</TurnPromptContainer>
        <ShakerContainer isMyTurn={isMyTurn}>
          <TimerIcon fontSize="large" />
        </ShakerContainer>
      </TurnInfoContainer>
    </Container>
  );
}

const Container = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledPlayersView = styled(PlayersView)`
  flex-basis: 50%;
`;

const TurnInfoContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 50%;
`;

const TurnPromptContainer = styled('div')`
  margin: 0 ${({ theme }) => theme.spacing(1)};
`;

const shakePercentage = (value: number) => `${value * 0.8}%`;
const SHAKE_KEYFRAMES = keyframes`
  ${shakePercentage(100)} {
    transform: translate(0, 0);
  }
  ${shakePercentage(25)} {
    transform: translate(-2px, 0);
  }
  ${shakePercentage(75)} {
    transform: translate(2px, 0);
  }
`;
const ShakerContainer = styled('span')<{ isMyTurn: boolean }>`
  color: ${({ theme, isMyTurn }) =>
    isMyTurn ? 'inherit' : theme.palette.text.disabled};
  animation: ${({ isMyTurn }) =>
    isMyTurn
      ? css`
          ${SHAKE_KEYFRAMES} 1s ease infinite
        `
      : css`none`};
`;
