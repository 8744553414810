import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import invariant from 'invariant';
import {
  selectVictory,
  selectIsCurrentClientAPlayer,
  selectClientsById,
  selectSelfClient,
} from '../roomSelectors';
import { Typography, Button } from '@mui/material';
import { AppDispatch } from '../../../app/store';
import { chooseTimeMachineSnapshotSelectedIndex } from '../roomSlice';
import { ClientAckEndOfGame } from '../../../messages/out';
import { useSuperTMasonClient } from '../../../messages/superTMasonClient';

export function EndedDisabledOverlay() {
  const dispatch = useDispatch<AppDispatch>();

  const masonClient = useSuperTMasonClient();

  const victory = useSelector(selectVictory);
  invariant(victory != null, 'Victor should be non-null if game is completed');

  const self = useSelector(selectSelfClient);
  invariant(self != null, 'Self client should be available');

  const isCurrentClientAPlayer = useSelector(selectIsCurrentClientAPlayer);
  const byId = useSelector(selectClientsById);

  let victoryTextNode;
  if (victory.type === 'Tie') {
    victoryTextNode = (
      <>
        <Typography variant="h4" color="secondary">
          It's a tie!
        </Typography>
        <BodyText>A stalemate settles it. Well fought!</BodyText>
      </>
    );
  } else {
    const victorClient = byId[victory.clientId];
    invariant(victorClient != null, 'Victor should have client');

    if (isCurrentClientAPlayer) {
      if (self.id === victorClient.id) {
        victoryTextNode = (
          <>
            <Typography variant="h4" color="secondary">
              You have won!
            </Typography>
            <BodyText>Through great skill, you have come out on top!</BodyText>
          </>
        );
      } else {
        victoryTextNode = (
          <>
            <Typography variant="h4" color="primary">
              You have lost!
            </Typography>
            <BodyText>A valiant effort&hellip; but not quite enough.</BodyText>
          </>
        );
      }
    } else {
      // Spectator should see the winning player name
      victoryTextNode = (
        <>
          <Typography variant="h4">
            <SecondaryColorSpan>{victorClient.displayName}</SecondaryColorSpan>{' '}
            has won!
          </Typography>
          <BodyText>Thanks for watching!</BodyText>
        </>
      );
    }
  }

  const onViewMoves = () => {
    // View moves from the beginning
    dispatch(chooseTimeMachineSnapshotSelectedIndex({ index: 0 }));
  };
  const viewMovesButtonNode = (
    <Button
      onClick={onViewMoves}
      variant="contained"
      color="primary"
      size="small"
    >
      View Moves
    </Button>
  );
  let controlsNode;
  if (isCurrentClientAPlayer) {
    const onReset = () => {
      masonClient.query<ClientAckEndOfGame>({
        type: 'ClientAckEndOfGame',
      });
    };
    const resetButtonNode = (
      <Button
        onClick={onReset}
        variant="contained"
        color="primary"
        size="small"
      >
        Reset
      </Button>
    );
    controlsNode = (
      <>
        {viewMovesButtonNode}
        {resetButtonNode}
      </>
    );
  } else {
    controlsNode = viewMovesButtonNode;
  }

  return (
    <Container>
      {victoryTextNode}
      <ControlsContainer>{controlsNode}</ControlsContainer>
    </Container>
  );
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ControlsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;

const SecondaryColorSpan = styled('span')`
  color: ${({ theme }) => theme.palette.secondary.main};
`;

type BodyTextProps = {
  children: React.ReactNode;
};
function BodyText({ children }: BodyTextProps) {
  return (
    <Typography paragraph={true} variant="body2">
      {children}
    </Typography>
  );
}
