import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { useSuperTMasonClient } from '../../../messages/superTMasonClient';
import { Button, Typography } from '@mui/material';
import type { ClientBeginGame } from '../../../messages/out';
import { selectIsGameBeginPossibleBySelf } from '../roomSelectors';

export function BeforeStartDisabledOverlay() {
  const masonClient = useSuperTMasonClient();
  const canStart = useSelector(selectIsGameBeginPossibleBySelf);

  const onBeginGame = () => {
    masonClient.query<ClientBeginGame>({
      type: 'ClientBeginGame',
    });
  };

  return (
    <Container>
      <Typography paragraph={true} variant="body2">
        There is no game currently being played.
      </Typography>
      {canStart && (
        <Button
          onClick={onBeginGame}
          variant="contained"
          color="primary"
          size="small"
        >
          Begin Game
        </Button>
      )}
    </Container>
  );
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
